<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'RestructuringAgreement', title: 'Договір реструктуризації' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_restructuring)"
        :modal="show_restructuring_dialog"
        :non-clickable="nonClickable"
        @updateItemModal="restructuringUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Договори реструктуризації
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success" v-if="!readOnly">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openRestructuringCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити новий договір реструктуризації</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="flat_services"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onRestructuringItemClick"
    >
      <template v-slot:item.icon="{ item }">
          <v-icon :color="getIcon(item.icon, 'color')" size="26" class="mr-2">
            {{ getIcon(item.icon, 'icon') }}
          </v-icon>
      </template>

      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>
      <template v-slot:item.all_service="{ item }">
        <v-simple-checkbox :value="item.all_service" color="success" class="mt-0"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {accountIcons} from "@/utils/icons";
import {GET_ALL_RESTRUCTURING_AGREEMENT} from "@/store/actions/restructuring_agreement";

export default {
  name: "HWP_RestructuringAgreements",
  components: { MainModal },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    nonClickable: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      selected_restructuring: {},
      show_restructuring_dialog: false,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Власник дог.', value: 'agreement_owner' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
        { text: 'Місяців', value: 'months' },
        { text: 'Борг', value: 'debt_sum' },
        { text: 'Міс. сума', value: 'pay_by_month' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      flat_services: 'getRestructuringAgreements',
      flat: 'getFlat',
      settings: 'getGeneralSettings',
      current_month: 'getCurrentMonth',
    }),
  },
  methods: {
    ...mapActions({
      fetchRestructuringAgreement: GET_ALL_RESTRUCTURING_AGREEMENT
    }),
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    restructuringUpdateItemModal(payload) {
      this.show_restructuring_dialog = false
      this.selected_restructuring = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openRestructuringCreateDialog() {
      this.selected_restructuring = {
        person_hash: this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash,
        date_start: this.current_month,
        is_legacy: false,
        agreement_owner: this.flat.flat_owner?.full_name || ''
      }
      this.show_restructuring_dialog = true
    },
    onRestructuringItemClick(payload) {
      if (this.readOnly || this.nonClickable) {
        this.selected_restructuring = {}
        this.show_restructuring_dialog = false
        return
      }

      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash

      this.selected_restructuring = local_payload
      this.show_restructuring_dialog = true
    },
  },
  mounted() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchRestructuringAgreement({person_hash: person_hash, is_legacy: false})
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchFlatServices({person_hash: payload, is_legacy: false})
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>